import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import phoneImg from '../../../assest/Solutions-Token/Intro.png';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import benefits from '../../../assest/Solutions-Token/Benefits 1.png'
import './data-tokenization.scss';
//import { OverlapBoxTechno } from '../../technopage/techno.component';
import {  OverlapBoxFeature } from '../features-page/features-page.component';


 import global from '../../../assest/Solutions-Token/Benefits2.png'
 import global1 from '../../../assest/Solutions-Token/Token Transparent.png'
// import dataAnalytics from '../../../assest/25 - Age Verification/9624 man.png'

//import {  MediumCard } from '../platformpage/platform.component';
//import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';

export const DataTokenization = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Data Tokenization' uris='Tokenization' />
    <OverlapBoxTechno
      img={phoneImg}
      title='The Ultimate Choice for Advanced Data Security and Compliance.'
    >
      <p>
      In an era where data breaches and cyber threats are becoming increasingly sophisticated, EarthID offers a <b>Tokenization & Data Protection</b> solution that doesn&apos;st just meet industry standards—it sets them. 
      Our platform is designed for organizations that prioritize data security and privacy, providing robust protection tailored to your unique business needs.
      </p>
      <p>
      By replacing sensitive data with unique tokens, we ensure your information is protected from breaches while maintaining seamless
       operations, all while meeting the highest standards of security and compliance.

      </p>
      <p>
      Tokenization significantly lowers the cost and effort needed to comply with internal security policies and regulations, including the EU&apos;s General Data Protection Regulation (GDPR), 
      India&apos;s Digital Personal Data Protection (DPDP), California Consumer Privacy Act (CCPA) in the U.S., and the Payment Card Industry Data Security Standard (PCI-DSS).

      </p>
     
    </OverlapBoxTechno>
    <SectionRight
        title="Secure Your Sensitive Data: What Can Be Tokenized?"
        title1="Personally Identifiable Information (PII)"
        content1= "First and last names, date of birth, Social Security numbers, driver's license numbers, and more."
        
        title2="Protected Health Information (PHI)"
       content2= "Gender, contact details, health insurance numbers, prescription numbers, and more."

       title3="Payment Data"
       content3= "Credit/debit card numbers (PAN), expiration dates, CVV, cardholder names, and more."

       title4="Automated Clearing House (ACH) Account Data"
       content4= "Bank account numbers, routing numbers, customer names, and more."
        imgPath={global}
        customClass="feature-box-image1" // Unique class for the first SectionRight
      ></SectionRight>

<SectionRight
        title="How Our Solution Works?"
        desc="Vault-based tokenization is a robust data security solution that replaces sensitive data with tokens stored in a secure vault, ensuring that the original information is fully protected. This method leverages two primary techniques: Random Tokenization and Format-Preserving Encryption (FPE), each designed to meet different security, and compliance needs while maintaining the usability of your data."
        title1="Personally Identifiable Information (PII)"
        content1= "First and last names, date of birth, Social Security numbers, driver's license numbers, and more."
        
        title2="Protected Health Information (PHI)"
       content2= "Gender, contact details, health insurance numbers, prescription numbers, and more."

       title3="Payment Data"
       content3= "Credit/debit card numbers (PAN), expiration dates, CVV, cardholder names, and more."

       title4="Automated Clearing House (ACH) Account Data"
       content4= "Bank account numbers, routing numbers, customer names, and more."
        imgPath={global1}
        customClass="feature-box-image2" // Unique class for the second SectionRight
      ></SectionRight>
  
      <OverlapBoxFeature  title='Solution Benefits'>
        <EarthidBox />
      </OverlapBoxFeature>
     
      {/* <SectionRight
        title="Global Coverage"
        title1="11K+ Documents Supported"
        content1= "EarthID supports over 11,500 identity documents from more than 230 countries and territories. Our extensive global reach allows you to verify your user's age from anywhere in the world."
        
        title2="45+ Languages and Dialects Supported"
       content2= "We have tailored our end-user flows to cater to the world's largest markets, providing support for 45+ languages and dialects. By offering a wide range of languages, we ensure that we can serve a diverse customer base. Our commitment to expanding our language offerings helps us meet the strategic goals of our partners."
        imgPath={global}
      ></SectionRight>
      <SectionLeft
        title="Advanced Security Measures"
        title1="Device and Network Analytics"
        content1= "As part of our comprehensive fraud prevention measures, EarthID analyzes the device and network used during a verification session, assigning each a unique fingerprint. This, along with hundreds of other parameters and checks for inconsistencies in personal data on the ID, ensures a robust verification process before delivering results."
        
        title2="Liveness Detection"
       content2= "EarthID&apos;s advanced liveness detection technology determines if a session or user is real or a spoof attempt, all without requiring extra steps from your customers. This seamless process enhances security while maintaining a smooth user experience."
        imgPath={dataAnalytics}
      ></SectionLeft> */}
    <Getstarted/>
    <Footer />
  </div>
);

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-distributee'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

// const MoreBox = props => {
//     return (
//       <div className='kys-div'>
//         <div className='kys-platform-content'>
//           <MoreCard icon={kysBulletPoint} title='Credential Issuance'
//             desc='Organizations, institutions, or individuals can issue digital credentials using our platform. These credentials are securely encoded with essential information, such as the issuer&apos;s identity, recipient&apos;s details, and the specific achievement or qualification. Our system ensures the authenticity of the data through advanced cryptographic techniques.'
//           />
//           <MoreCard icon={kysBulletPoint} title='Secure Storage'
//             desc="Once issued, the digital credentials are stored in a secure, tamper-proof environment. Each credential is assigned a unique identifier and is stored on a decentralized blockchain network. This ensures that the credential remains immutable and can be independently verified at any time."
//           />
//           <MoreCard icon={kysBulletPoint} title='Verification Process'
//             desc='To verify a credential, the recipient or a third party can scan a QR code or enter a unique verification link provided with the digital credential. Our system then retrieves the credential&apos;s data from the blockchain and verifies its authenticity. This process confirms that the credential is legitimate and has not been altered since its issuance.'
//           />
//           <MoreCard icon={kysBulletPoint} title='User-Friendly Access'
//             desc='Recipients of digital credentials can easily access and manage their credentials through our user-friendly web and mobile interfaces. They can share their credentials with employers, educational institutions, or other relevant parties with just a few clicks, ensuring that their achievements are recognized and verified swiftly.'
//           />
//         </div>
//         <div className='kys-platform-content'>
//           <MoreCard icon={kysBulletPoint} title='Interoperability'
//             desc='Our solution is designed to be compatible with various standards and frameworks for digital credentials. This interoperability ensures that our credentials can be used and verified across different platforms and systems, enhancing their utility and acceptance globally.'
//           />
//           <MoreCard icon={kysBulletPoint} title='Privacy and Security'
//             desc='We prioritize the privacy and security of our users. Personal data and credential information are encrypted and protected by stringent security protocols. Our platform complies with international data protection regulations, ensuring that users&apos; information is handled responsibly and ethically.'
//           />
//           <MoreCard icon={kysBulletPoint} title='Real-Time Updates'
//             desc='Issuers can update or revoke credentials in real-time, ensuring that the information remains current and accurate. This feature is particularly useful for credentials that may expire or need periodic renewal, such as professional certifications or licenses.'
//           />
//         </div>
       
  
//       </div>
  
  
//     );
//   };

//   const OverlapBoxIndustry = props => {
//     return (
//       <div className='overlap-boxx'>
//         <p className='box-title'>{props.title}</p>
//         {props.children}
//       </div>
//     );
//   };

//   const LargeCard = ({ img, desc, title }) => {
//     return (
//       <div className='large-card'>
//         <img src={img} className='large-card-imgg' alt='largeCardImg' />
//         <p className='large-card-title'>{title}</p>
//         <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
//       </div>
//     );
//   };

  const EarthidBox = props => {
    return (
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Automate Secure Key Management'
            desc='Efficiently manage the entire key lifecycle and algorithm usage while utilizing FIPS 140-2 compliant HSM for secure key storage.'
          />
          <KYSCard icon={kysBulletPoint} title='Achieve Rapid Compliance'
            desc='Safeguard each sensitive data element with unique protection, supported by comprehensive audit and logging features within a unified dashboard.'
          />
  
          <KYSCard icon={kysBulletPoint} title='Minimize Data Breaches'
            desc="Encrypt, tokenize, and mask sensitive data across your infrastructure, ensuring that attackers, insiders, cloud providers, and even EarthID cannot access your data or keys."
          />
      
        </div>
        <div className='kys-platform-content'>
          
          <KYSCard icon={kysBulletPoint} title='Fast and Seamless Encryption Setup'
            desc='Seamlessly integrate encryption, tokenization, and masking into your environment within minutes, without the need to overhaul your existing storage infrastructure.'
          />
          <KYSCard icon={kysBulletPoint} title='Protect Any Data Type'
            desc='Securely encrypt, tokenize, or mask both structured and unstructured data, regardless of where it resides — in databases, warehouses, on-premises, or in cloud.'
          />
          <KYSCard icon={kysBulletPoint} title='Seamless Integration'
            desc='Easily incorporate data security into your applications, databases, data warehouses, API gateways, and SaaS applications with maximum flexibility and ease.'
          />
          
        </div>
        <div className='kys-platform-img'> <img src={benefits} className='kys-platform-imagee' alt='kysCardIcon' /></div>
  
      </div>
  
  
    );
  };


  // const SectionRight = props => {
  //   return (
  //     <div className='feature-box-right'>
  //       <p className='title-feature'>{props.title}</p>
  //       <div className="display-flex">
  //         <div className='feature-box-content'>
  //           <div className='overallcontent'>
  //           <p className='title1-feature'>{props.title1}</p>
  //         <p className='content1-feature'>{props.content1}</p>
  //         <p className='title1-feature'>{props.title2}</p>
  //         <p className='content1-feature'>{props.content2}</p>
  //           </div>
          
  //         </div>
  //         <div className='feature-box-image'>
  //           <img src={props.imgPath} />
  
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const SectionLeft = props => {
  //   return (
  //     <div className='feature-box-left'>
  //       <p className='title-feature'>{props.title}</p>
  //       <div className="display-flex">
  //       <div className='feature-box-image'>
  //           <img src={props.imgPath} />
  
  //         </div>
  //         <div className='feature-box-content'>
  //           <div className='overallcontent'>
  //           <p className='title1-feature'>{props.title1}</p>
  //         <p className='content1-feature'>{props.content1}</p>
  //         <p className='title1-feature'>{props.title2}</p>
  //         <p className='content1-feature'>{props.content2}</p>
  //           </div>
          
  //         </div>
          
  //       </div>
  //     </div>
  //   );
  // };

 const KYSCard = ({ icon, desc, title }) => {
    return (
      <div className='kys-card'>
        <div className="key-icon-col"><img src={icon} className='kys-card-icon' alt='kysCardIcon' /></div>
        <div className="key-text-col">
          <p className='kys-card-title'>{title}</p>
          <p className='kys-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
        </div>
      </div>
    );
  };

 const OverlapBoxTechno = ({ img, title, children }) => {
    return (
      <div className='overlap-box-technoo'>
        <img src={img} alt='phone-img' className='techno-imgDT' />
        <div className='techno-content'>
          <p className='techno-title'>{title}</p>
          <div className='rest-para'>{children}</div>
        </div>
      </div>
    );
  };


  // const MediumCard = ({ icon, desc, title }) => {
  //   return (
  //     <div className='medium-card'>
  //       <img src={icon} className='medium-card-icon-platform' alt='mediumCardIcon' />
  //       <p className='medium-card-title'>{title}</p>
  //       <p className='medium-card-desc-platform'>{!desc ? 'right now description is not available' : desc}</p>
  //     </div>
  //   );
  // };

  const SectionRight = props => {
    return (
      <div className='feature-box-right'>
        <p className='title-feature'>{props.title}</p>
        <p className='desc1-feature'>{props.desc}</p>
        <div className="display-flex">
          <div className='feature-box-content'>
            <div className='overallcontent'>
            <p className='title1-feature'>{props.title1}</p>
          <p className='content1-feature'>{props.content1}</p>
          <p className='title1-feature'>{props.title2}</p>
          <p className='content1-feature'>{props.content2}</p>
          <p className='title1-feature'>{props.title3}</p>
          <p className='content1-feature'>{props.content3}</p>
          <p className='title1-feature'>{props.title4}</p>
          <p className='content1-feature'>{props.content4}</p>
            </div>
          
          </div>
          <div className={`feature-box-image ${props.customClass}`}>
          <img src={props.imgPath} alt="feature-img" />
        </div>
        </div>
      </div>
    );
  };