import React from 'react';
import './ssi-page.styles.scss';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import ssi from '../../../assest/SSI.png';

const DIDPage = () => {
  return (
    <div className='ssi-platform'>
      <NavBar />
      <HeaderHalf title='Decentralized Identity' uris='DID' />
      <OverlapBox
        title=''
        className='overlap-box'>
        <div>
          <div>
            <div className="ssi-block">
              <p className="ssi-sub-title">Decentralized Identity: Redefining Digital Trust</p>
              <p className='ssi-text'>
              In today&apos;s digital age, identity is crucial for secure online interactions, whether accessing services, conducting financial transactions, or protecting personal data. Traditional identity management systems, often centralized, are rife with inefficiencies and vulnerabilities particularly due to increasing data breaches and privacy concerns. This is where Decentralized Identity comes into play, offering a revolutionary approach to digital identity.</p>
              <p className='ssi-text'>
             <b>What is Decentralized Identity? </b> 
              </p>
              <p className='ssi-text'>
              Decentralized Identity empowers individuals and organizations to manage their own digital identities without relying on a central authority. Unlike traditional systems where identity data is stored and controlled by a third party, Decentralized Identity enables users to create, own, and control their identity data. This decentralized approach ensures higher security, privacy, and user autonomy. 
              </p>
              <p className='ssi-text'>
             <b>Core Principles of Decentralized Identity </b> 
             </p>
              <p className='ssi-text'>
              - Decentralization: Identity data is not stored in a central database, reducing the risk of mass data breaches. <br/>

- User Control and Consent: Users have full control over their identity information, deciding what data to share and with whom. <br/>

- Privacy and Security: By leveraging blockchain technology and cryptographic methods like Zero-Knowledge Proofs, Decentralized Identity ensures that personal data remains private and secure. <br/>

- Interoperability: Decentralized Identity solutions are designed to work seamlessly across different platforms and systems, providing a unified user experience. 
             </p>
             <p className='ssi-text'>
             <b>Benefits of Decentralized Identity </b> 
             </p>
              <p className='ssi-text'>
             1. Enhanced Security: Decentralized Identity minimizes the risk of identity theft and fraud by eliminating central points of failure. <br/>

2. Improved Privacy: Users can share only the necessary information without exposing their full identity. <br/>

3. Streamlined Processes: Decentralized Identity simplifies verification processes such as Know Your Customer (KYC) and onboarding, reducing time and operational costs. <br/>

4. User Empowerment: Individuals and organizations regain control over their digital identities, fostering trust and transparency. 
</p>
              <div className='image-ssi-container'>
                <img src={ssi} className='img-for-ssi' alt='ssi-img' />
              </div>
            </div>
            <div className="ssi-block">
              <p className='ssi-text'>
              <b>EarthID: Redefining Decentralized Identity Systems </b>
              </p>
              <p className='ssi-text'>
              As the digital landscape continues to evolve, the need for secure, reliable, and privacy-preserving identity systems has never been more critical. EarthID, a decentralized identity platform, offers a forward-thinking solution to the limitations of traditional identity management. At the core of EarthID’s approach are four key elements: blockchain technology, a decentralized identity wallet, decentralized identifiers (DIDs), and verifiable credentials (VCs). Together, these components empower users with greater control over their identities, while enhancing security and trust in digital transactions. 
              </p>
              <p className='ssi-text'>
              <b>Key Elements of EarthID’s Decentralized Identity System </b>
              </p>
              <p className='ssi-text'>
              <b>1. Blockchain </b> <br/>
EarthID leverages blockchain technology to create a decentralized, immutable ledger that records all transactions and interactions securely. The blockchain functions as the backbone of the system, ensuring that identity information is distributed across the network rather than stored centrally. This makes altering or hacking the system incredibly difficult, if not impossible, enhancing trust and security. 

Since all identity-related transactions are recorded on the blockchain, EarthID ensures transparency, security, and privacy. Users’ data is stored in a decentralized manner, eliminating the need for a single point of failure or vulnerability that is common in traditional systems. EarthID users can be confident that their identities and data are shielded from malicious attacks and unauthorized access. 
              </p>
              <p className='ssi-text'>
              <b>2. Decentralized Identity Wallet </b> <br/>
              Decentralized Identity Wallet: 
EarthID offers a decentralized identity wallet, an application that allows users to manage their personal identity data, create decentralized identifiers, and store verifiable credentials. This wallet gives users complete control over what information they choose to share and with whom. 

With EarthID’s wallet, users can hold various credentials, such as government-issued IDs, educational qualifications, or employment certificates, in a digital, cryptographically secure format. By using their decentralized identity wallet, users can decide when and how to share their credentials with verifiers, giving them the autonomy to manage their own identities without relying on intermediaries or centralized authorities. 
</p>
              <p className='ssi-text'>
              <b>3. Decentralized Identifier (DID) </b> <br/>
              At the heart of EarthID’s decentralized identity system is the decentralized identifier (DID). A DID is a unique string of numbers and letters that serves as the user’s identity on the blockchain. It contains essential verification information, such as the user’s public key, enabling secure communication and verification processes. <br/> <br/>

A DID does not inherently contain personal information but instead acts as a reference point for the individual’s credentials. Users create their DIDs through EarthID’s decentralized wallet, and these DIDs can be used across different platforms and services, enhancing both interoperability and privacy. Importantly, the DID is owned and controlled by the user, ensuring that their identity cannot be manipulated by third parties. 
</p>
              <p className='ssi-text'>
              <b>4. Verifiable Credentials (VCs) </b> <br/>
              EarthID uses verifiable credentials to create a secure, digital version of traditional paper or digital credentials. VCs are cryptographically signed credentials that can be shared with verifiers to authenticate information, such as a person’s age, citizenship, or qualifications. <br/>

Verifiable credentials offer the highest levels of privacy and security. For example, if a person needs to prove they are over the age of 18, they can share only the relevant VC from their decentralized identity wallet, without revealing additional information like their full birthdate. This selective disclosure preserves privacy while enabling seamless verification. <br/> <br/>
The VC system within EarthID operates with three primary parties: <br/>

- Holder: The holder is the individual who creates their DID using EarthID’s wallet and stores the verifiable credentials issued to them. They can manage and control these credentials, sharing them when necessary. <br/>

- Issuer: The issuer is the trusted authority, such as a government, educational institution, or employer, that generates and signs verifiable credentials using their private key. For instance, a university might issue a degree certificate as a VC, which the holder can then store in their EarthID wallet. <br/>

- Verifier: The verifier is any organization or party that needs to confirm the authenticity of a credential. For example, a company hiring a new employee might use EarthID’s system to verify the candidate’s degree by checking the issuer’s public DID on the blockchain. The verifier does not need to contact the issuing institution directly, as the blockchain contains the verification information needed to trust the credential. 
 </p>
              <p className='ssi-text'>
              <b>EarthID’s Advantages in the Decentralized Identity Ecosystem </b>
              </p>
              <p className='ssi-text'>
              <b>- Data Security and Privacy </b> <br/>
              One of EarthID’s most significant benefits is the ability to protect personal data through encryption and decentralization. With VCs and DIDs, personal information does not need to be shared in full, and users can disclose only what is necessary, minimizing their exposure to data breaches and misuse. </p>
<p className='ssi-text'>
              <b>- User Control and Ownership  </b> <br/>
              
Unlike centralized systems where identity data is managed by corporations or governments, EarthID ensures that users retain full control and ownership of their identities. With the decentralized identity wallet, users can manage their credentials and DIDs, knowing that they hold the keys to their digital identities. 
</p>
<p className='ssi-text'>
              <b>- Interoperability Across Platforms </b> <br/>
             
EarthID’s decentralized identity system is designed to work across platforms and borders. This interoperability is vital in a globalized world where individuals may need to verify their identities in multiple countries, systems, or platforms. EarthID enables a consistent and secure way to manage identity verification in such a scenario. 
</p>
              <p className='ssi-text'>
              <b>Applications of Decentralized Identity </b>
              </p>
              <p className='ssi-text'>
              <b> Financial Services</b> <br/> Streamline KYC processes, reducing onboarding times and enhancing customer experience. <br/>

              <b>Healthcare</b>  <br/>Enable secure and private sharing of medical records, improving patient care. <br/>

              <b>Supply Chain</b> <br/> Ensure transparency and traceability, enhancing trust among stakeholders. <br/>

              <b>Education</b> <br/> Simplify verification of academic credentials, making it easier for students and institutions to share and validate achievements. 
              </p>
              
              <p className='ssi-text'>
              EarthID exemplifies the transformative potential of decentralized identity systems, offering users secure, privacy-preserving, and user-controlled solutions to managing their digital identities. With its blockchain-based infrastructure, decentralized identity wallet, DIDs, and verifiable credentials, EarthID is at the forefront of redefining how individuals interact with interact with financial services, apply for employment and access digital ecosystems. 
              </p>
              

             
            </div>
          </div>
          <div className="ssi-block">
            <p className='ssi-sub-title small-text'>
            Embrace the future of digital identity with EarthID. 
            </p>
          </div>
        </div>
      </OverlapBox>
      <Getstarted/>
      <Footer />
    </div>
  );
};

//odfgh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-ssi'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};


export default DIDPage;